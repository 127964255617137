/*body {*/
/*  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,*/
/*    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,*/
/*    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';*/
/*  padding: 0 1rem 1rem 1rem;*/
/*  margin: 0 auto;*/
/*  background: #fff;*/
/*  color: #333;*/
/*}*/

li,
p {
  line-height: 1.5rem;
}

a {
  font-weight: 500;
}

hr {
  border: 1px solid #ddd;
}

iframe {
  background: #ccc;
  border: 1px solid #ccc;
  height: 10rem;
  width: 100%;
  border-radius: 0.5rem;
  filter: invert(1);
}

.time-to-rock-logo-menu {
  background-image: url(../public/logos/timetorock_logo.png);
  background-size: contain;
  position: relative;
  display: block;
  top: 0;
  text-decoration: none;
  background-position: left center;
  background-repeat: no-repeat;
  width: 200px;
  height: 50px;
  transition: all 250ms;
  margin-top: 5px;
}

@media (min-width: 1000.001px) {
  .time-to-rock-logo-menu {
    margin-left: 28px;
  }
}

input[readonly] {
  background-color: #eeeeee;
}

.button-below-form {
  margin-bottom: 25px;
}

#countrySelect {
  width: 100%;
  border: lightgrey solid 0.5px;
  border-radius: 0.5em;
  margin-bottom: 0.3em;
}

.darkUnderlineStyle {
  border-bottom: solid 1px darkslategrey;
}
