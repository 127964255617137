.applicationStatus_processing,
.applicationStatus_paused {
  background-color: lightyellow;
}
.applicationStatus_accepted {
  background-color: lightgreen;
}
.applicationStatus_rejected,
.applicationStatus_ignored {
  background-color: lightpink;
}
